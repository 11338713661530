define('fancyBoxFeatures', ['jquery', 'fancyBox', 'scriptOnComplete'], function($, fancybox, scriptOnComplete) {
  
  var fancyBoxFeatures = function() {

    var self = this;
    var _defaultConfig = {
      'width': 600,
      'minWidth': 350,
      'maxWidth': 980,
      'minHeight': 20,
      'margin': 0,
      'padding': 0
    };

    var setupDefaults = function(config) {

      config = config || self._defaultConfig;
      $.fancybox.defaults = $.extend($.fancybox.defaults, config);
    };

    var setupProductPromo = function(config) {

      config = config || {
        'width': 200,
        'height': 'auto',
        'autoDimensions': false
      };

      // Product page promo popup
      if ($('.product-promo .product-promo-desc').length) {
        $('.product-promo h3').css('cursor', 'pointer')
          .click(function() {
            var html = $('.product-promo').wrap('<div />').parent().html();

            $.fancybox(html, config);
          });
      }

    };

    var setupProductImage = function(config) {

      config = config || {
        'margin': 50,
        'padding': 5,
        'arrows': false
      };

      //load product image in fancybox
      $('.fb-zoom').fancybox(config);
    };

    var setupVariationOnPages = function(siteObj) {

      var $body = $('body');
      // Initial HTML markup to be added into Fancybox. Contains the loading spinner and hidden close button.
      var initial_fb_content = '<div class="basketAlert"><img src="' + siteObj.siteNonSecureURL
        + 'common/images/loading.gif" alt="Loading" class="fb-loader" /></div>';

      // Handle list page variations products.
      if ($body.hasClass('list') || $body.hasClass('dept') || $body.hasClass('searchlist')
        || $body.hasClass('product')) {
        $body.on('click', '.quickview, .quickview-buy', function() {

          var contents;
          var page_url = $(this).attr('href');
          var product_id = $(this).closest('.item').attr('rel');
          var basket_utils = siteObj.fn.basket;
          var fb_opts = {
            afterShow: function() {
              // Bind close events
              $('.product-quick-view').delegate('.close-fb', 'click', function(e) {
                $.fancybox.close();
                e.preventDefault();
              });
            },
            beforeClose: function() {
              $('.product-quick-view').undelegate('.close-fb');
            }
          };

          siteObj.productID = product_id;

          if ($().fancybox) {
            $.fancybox(initial_fb_content, $.extend({
              'closeBtn': false
            }, fb_opts));
          }

          $.ajax({
            url: page_url,
            cache: false,
            data: 'ajax=true',
            dataType: 'html',
            success: function(result) {
              contents = window.quickView(result, page_url);
              $.fancybox.close();
              $.fancybox(contents, $.extend({
                'closeBtn': false
              }, fb_opts));

              $('.fancybox-inner').fadeIn('400', function() {

                $(this).find('.productoptions');

                if ($('input[name=\'cheapest-product-id\']').length) {
                  basket_utils.preselected_product = true;
                }
                $('.buying-area button[disabled=\'disabled\']')
                  .removeAttr('disabled')
                  .addClass('disabled');
              });
            }
          });
          return false;
        });
      }

    };

    var sciconBrandPage = function() {
      /* temp stuff for scicon brand page*/

      if ($('body').is('.section-scicon')) {

        $('.scicon-pull').click(function() {
          $('.scicon-more').slideToggle('fast');
        });
        $('.team-more-close').click(function() {
          $('.scicon-more').slideToggle('fast');
        });

        if ($().fancybox) {
          var $fbLinks = $('.fb-link');

          $.each($fbLinks, function() {
            var $fbLink = $(this);
            var fbHref = $fbLink.attr('href').replace(new RegExp('watch\\?v=', 'i'), 'embed/');

            $fbLink.fancybox({
              'padding': 10,
              'autoScale': false,
              'transitionIn': 'none',
              'transitionOut': 'none',
              'title': false,
              'width': 680,
              'height': 495,
              'href': fbHref,
              'type': 'iframe'
            });
          });
        }
      }
    };

    var _setupFancyBoxEvents = function() {

      if ($().fancybox) {
        var $question = $('.question');
        var $fbClose = $('.fb-close');
        var $selector = $('.fb');
        var $onLoadSelector = $('.fb-onload');
        var fancybox_opts = {
          closeBtn: false,
          init: function($this, markup) {
            var content;

            if (markup === true) {
              content = $this.html();
            }

            $this.fancybox({
              content: content,
              onComplete: function() {
                fancybox_opts.events();
              },
              afterShow: function() {
                fancybox_opts.events();
              },
              onClosed: function() {
                fancybox_opts.cleanup();
              }
            });
          },
          events: function() {
            $fbClose.on('click', function(e) {
              e.preventDefault();
              $.fancybox.close(true);
            });
          },
          cleanup: function() {
            $question.find('[data-answer]').unbind('click');
          }
        };

        fancybox_opts.init($selector);
        fancybox_opts.init($onLoadSelector, true);
        $onLoadSelector.click();
      }
    };

    var init = function() {
      self._setupFancyBoxEvents();
      scriptOnComplete.write(self._name);
    };

    self._setupFancyBoxEvents = _setupFancyBoxEvents;
    self.setupDefaults = setupDefaults;
    self.setupProductPromo = setupProductPromo;
    self.setupProductImage = setupProductImage;
    self.setupVariationOnPages = setupVariationOnPages;
    self.sciconBrandPage = sciconBrandPage;
    self.init = init;
    self._name = 'fancyBoxFeatures';
    self._defaultConfig = _defaultConfig;
  };

  var aFancyBox = new fancyBoxFeatures();
  aFancyBox.init();
  return aFancyBox;
});
